<template>
  <v-card flat outlined class="pa-5 overflow-y-auto" max-height="400">
    <v-card-title class="text-h5 pt-0 px-0">Mitarbeiterstatus</v-card-title>
    <v-list dense>
      <v-list-item
        v-for="(user, index) in mitarbeiter"
        :key="`mitarbeiter-${index}`"
      >
        <v-list-item-icon>
          <v-icon :color="user.status.color">{{ icons.mdiCircle }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ user.vorname }} {{ user.nachname }}: {{ user.status.status }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action
          v-if="$hasPermission(authUser, ['Verwaltung'])"
          class="my-0"
        >
          <v-menu left offset-y offset-overflow>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon tile v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiChevronDown }}</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                link
                v-for="(status, i) in statuses"
                :key="`status-${i}`"
                @click="updateStatus(user, status)"
              >
                <v-list-item-title> {{ status.text }} </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mdiCircle, mdiChevronDown } from "@mdi/js";
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

export default {
  name: "DashboardWidgetUserStatus",
  data: () => ({
    icons: {
      mdiCircle,
      mdiChevronDown,
    },
    statuses: [
      { id: 1, text: "Anwesend", color: "success" },
      { id: 2, text: "Außer Haus", color: "error" },
      { id: 3, text: "Urlaub", color: "warning" },
      { id: 4, text: "Krank", color: "error" },
      { id: 5, text: "Home Office", color: "info" },
    ],
    loading: false,
    error: null,
    mitarbeiter: [],
  }),
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
  },
  async mounted() {
    await this.fetchMitarbeiter();
  },
  methods: {
    async fetchMitarbeiter() {
      const payload = {
        complete: true,
        status: "active",
      };

      const config = {
        params: {
          roles: [
            "Geschäftsführung",
            "Verwaltung",
            "Verkauf",
            "Planung",
            "Rechnungswesen",
            "Statik",
            "Bauleitung",
            "Allgemein",
          ],
        },
      };

      this.loading = true;

      await ApiService.getPersonal(payload, config)
        .then((res) => {
          this.mitarbeiter = res.data.data;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateStatus(user, status) {
      const payload = {
        uuid: user.uuid,
        status: status.text,
      };

      await ApiService.updateUserStatus(payload)
        .then(() => {
          this.fetchMitarbeiter();
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        });
    },
  },
};
</script>

<style scoped></style>
