import Layout from "@/views/backend/users/Layout.vue";
import LayoutInteressenten from "@/views/backend/users/interessenten/LayoutInteressenten.vue";
import ShowInteressent from "@/views/backend/users/interessenten/ShowInteressent.vue";
import LayoutMitarbeiter from "@/views/backend/users/mitarbeiter/LayoutMitarbeiter.vue";
import ShowCustomerLayout from "@/views/backend/users/kunden/show/ShowCustomerLayout.vue";
import LayoutCustomers from "@/views/backend/users/kunden/LayoutCustomers.vue";

export default [
  {
    path: "users",
    navigationName: "Benutzer",
    meta: { requiresAuth: true, searchFilter: true },
    component: Layout,
    children: [
      {
        path: "duplicates",
        name: "list-duplicates",
        navigationName: "Duplikate",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/backend/users/Duplicates.vue"),
      },
      /*********************
       *** Interessenten ***
       ********************/
      {
        path: "interessenten",
        navigationName: "Interessenten",
        meta: { requiresAuth: true, searchFilter: true },
        component: LayoutInteressenten,
        children: [
          {
            path: "/",
            name: "list-interessenten",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/views/backend/users/interessenten/ListInteressenten.vue"
              ),
          },
          {
            path: "unread",
            name: "list-interessenten-unread",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/views/backend/users/interessenten/ListInteressenten.vue"
              ),
          },
          {
            path: "inactive",
            name: "list-interessenten-inactive",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/views/backend/users/interessenten/ListInteressenten.vue"
              ),
          },
        ],
      },
      {
        path: "interessenten/new",
        name: "new-interessent",
        navigationName: "Neuer Benutzer",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/users/interessenten/NewInteressent.vue"),
      },
      {
        path: "interessenten/:id",
        meta: {
          requiresAuth: true,
        },
        component: ShowInteressent,
        props: true,
        children: [
          {
            path: "/",
            name: "show-interessent-details",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/views/backend/users/interessenten/InteressentDetails.vue"
              ),
          },
          {
            path: "documents",
            name: "show-interessent-documents",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/views/backend/users/interessenten/InteressentDocuments.vue"
              ),
          },
        ],
      },
      {
        path: "interessenten/:id/edit",
        name: "edit-interessent",
        navigationName: "Benutzer bearbeiten",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/users/interessenten/EditInteressent.vue"),
        props: true,
      },
      /*********************
       *** Kunden ***
       ********************/
      {
        path: "customers",
        meta: { requiresAuth: true, searchFilter: true },
        component: LayoutCustomers,
        children: [
          {
            path: "/",
            name: "list-customers",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/users/kunden/ListCustomers.vue"),
          },
          {
            path: "new",
            name: "new-customer",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/users/kunden/NewCustomer.vue"),
          },
          {
            path: ":id",
            meta: {
              requiresAuth: true,
            },
            component: ShowCustomerLayout,
            props: true,
            children: [
              {
                path: "details",
                name: "show-customer-details",
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/views/backend/users/kunden/show/CustomerDetails.vue"
                  ),
              },
              {
                path: "documents",
                name: "show-customer-documents",
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/views/backend/users/kunden/show/CustomerDocuments.vue"
                  ),
              },
              {
                path: "bauvorhaben",
                name: "show-customer-bauvorhaben",
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/views/backend/users/kunden/show/CustomerBauvorhaben.vue"
                  ),
              },
              {
                path: "invoices",
                name: "list-customer-invoices",
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import("@/views/backend/invoices/ListInvoices.vue"),
              },
              {
                path: "invoices/new",
                name: "new-customer-invoice",
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import("@/views/backend/invoices/NewInvoice.vue"),
              },
              {
                path: "invoices/incoming",
                name: "list-customer-incoming-invoices",
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/views/backend/users/kunden/show/invoices/CustomerIncomingInvoices.vue"
                  ),
              },
              {
                path: "invoices/incoming/new",
                name: "new-customer-incoming-invoice",
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/views/backend/invoices/incoming/NewIncomingInvoice.vue"
                  ),
              },
              {
                path: "invoices/partial",
                name: "list-customer-partial-invoices",
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/views/backend/users/kunden/show/invoices/CustomerPartialInvoices.vue"
                  ),
              },
              {
                path: "invoices/payments",
                name: "list-customer-invoice-payments",
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import("@/views/backend/invoices/payments/ListPayments.vue"),
              },
              {
                path: "invoices/reminders",
                name: "list-customer-invoice-reminders",
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/views/backend/invoices/reminders/ListInvoiceReminders.vue"
                  ),
              },
              {
                path: "invoices/:invoiceId/edit",
                name: "edit-customer-invoice",
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import("@/views/backend/invoices/EditInvoice.vue"),
              },
            ],
          },
          {
            path: ":id/edit",
            name: "edit-customer",
            navigationName: "Benutzer bearbeiten",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/users/kunden/EditCustomer.vue"),
            props: true,
          },
        ],
      },
      /*********************
       *** Mitarbeiter ***
       ********************/
      {
        path: "mitarbeiter",
        meta: { requiresAuth: true, searchFilter: true },
        component: LayoutMitarbeiter,
        children: [
          {
            path: "/",
            name: "list-mitarbeiter",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/users/mitarbeiter/ListMitarbeiter.vue"),
          },
          {
            path: "inactive",
            name: "list-mitarbeiter-inactive",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/users/mitarbeiter/ListMitarbeiter.vue"),
          },
          {
            path: "deleted",
            name: "list-mitarbeiter-deleted",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/users/mitarbeiter/ListMitarbeiter.vue"),
          },
          {
            path: "new",
            name: "new-mitarbeiter",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/users/mitarbeiter/NewMitarbeiter.vue"),
          },
          {
            path: ":id",
            name: "show-mitarbeiter",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/users/mitarbeiter/ShowMitarbeiter.vue"),
            props: true,
          },

          // {
          //   path: ":id",
          //   meta: {
          //     requiresAuth: true,
          //   },
          //   component: ShowMitarbeiter,
          //   props: true,
          //   children: [
          //     {
          //       path: "/",
          //       name: "show-mitarbeiter",
          //       meta: {
          //         requiresAuth: true,
          //       },
          //       component: () =>
          //         import(
          //           "@/views/backend/users/mitarbeiter/ShowMitarbeiterDetails"
          //         ),
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
];
